<template>
  <div id="SideMenu" class="dflx">
    <div class="box-1">
      <div class="logo">
        <img src="../assets/agriga-w.svg" alt="logo aplicación" />
      </div>
      <div class="msg-user">
        <p>
          ¡HOLA, <span>{{ identity.name | splitUp }}!</span>
        </p>
      </div>
      <div class="menu">
        <ul>
          <li
            :class="{ active: navOption == 'Dashboard' }"
            @click="activeMenu(0), setOption('Mi Página')"
          >
            <div class="text-menu">MI <span> PÁGINA</span></div>
            <div v-if="navOption == 'Mi Página'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>
          <!--<li :class="{active : navOption == 'Perzonalizacion' }" @click="activeMenu(0),setOption('Perzonalizacion')"><div class="text-menu"> <span>PERSONALIZACIÓN</span> </div><div v-if="navOption == 'Perzonalizacion'" class="icon-active"><div class="ico"></div> </div></li>-->
          <li
            :class="{ active: navOption == 'Usuarios' }"
            @click="
              activeMenu(0),
                setOption('Usuarios'),
                setHistoryOptionUsr('Default')
            "
          >
            <div class="text-menu"><span>USUARIOS</span></div>
            <div v-if="navOption == 'Usuarios'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>
          <li
            :class="{ active: navOption == 'Categorias' }"
            @click="
              activeMenu(0),
                setOption('Categorias'),
                setHistoryOptionCat('Default')
            "
          >
            <div class="text-menu"><span>CATEGORÍAS</span></div>
            <div v-if="navOption == 'Categorias'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>

          <li
            :class="{ active: navOption == 'MenuB' }"
            @click="activeMenu(3), setOption('MenuB')"
          >
            <div class="text-menu">MENÚ <span> BLOG</span></div>
            <div
              v-if="navOption == 'MenuB' || hidemenu3"
              class="icon-active-menu"
            >
              <div class="ico-2"></div>
            </div>
          </li>
          <li
            class="submenu"
            :class="{ active: navOption == 'BLOG' }"
            v-if="hidemenu3"
            @click="setOption('BLOG'), setHistoryOptionBlo('Default')"
          >
            &#10625; BLOG
          </li>
           <li
            class="submenu"
            :class="{ active: navOption == 'colaboradores' }"
            v-if="hidemenu3"
            @click="setOption('colaboradores'), setHistoryOptionClb('Default')"
          >
            &#10625; COLABORADORES
          </li>
           <li
            class="submenu"
            :class="{ active: navOption == 'TAG' }"
            v-if="hidemenu3"
            @click="setOption('TAG'), setHistoryOptionTg('Default')"
          >
            &#10625; ETIQUETAS
          </li>

         


          <li
            :class="{ active: navOption == 'MenuP' }"
            @click="activeMenu(1), setOption('MenuP')"
          >
            <div class="text-menu">MENÚ <span>PRODUCTOS</span></div>
            <div
              v-if="navOption == 'MenuP' || hidemenu"
              class="icon-active-menu"
            >
              <div class="ico-2"></div>
            </div>
          </li>

          <li
            class="submenu"
            :class="{ active: navOption == 'Brand' }"
            v-if="hidemenu"
            @click="setOption('Brand'), setHistoryOptionBnd('Default')"
          >
            &#10625; MARCAS
          </li>
           <li
            class="submenu"
            :class="{ active: navOption == 'Atributo' }"
            v-if="hidemenu"
            @click="setOption('Atributo'), setHistoryOptionAtt('Default')"
          >
            &#10625; ATRIBUTOS
          </li>
          <li
            class="submenu"
            :class="{ active: navOption == 'Producto' }"
            v-if="hidemenu"
            @click="setOption('Producto'), setHistoryOptionPro('Default')"
          >
            &#10625; PRODUCTOS
          </li>

          <li
            :class="{ active: navOption == 'Banner' }"
            @click="
              activeMenu(0), setOption('Banner'), setHistoryOptionCat('Default')
            "
          >
            <div class="text-menu"><span>BANNER</span></div>
            <div v-if="navOption == 'Banner'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>

          <li
            :class="{ active: navOption == 'MenuC' }"
            @click="activeMenu(2), setOption('MenuC')"
          >
            <div class="text-menu">MENÚ <span>TIENDA</span></div>
            <div
              v-if="navOption == 'MenuC' || hidemenu2"
              class="icon-active-menu"
            >
              <div class="ico-2"></div>
            </div>
          </li>
          <li
            class="submenu"
            :class="{ active: navOption == 'Cupon' }"
            v-if="hidemenu2"
            @click="setOption('Cupon'), setHistoryOptionCar('Default')"
          >
            &#10625; CUPÓN
          </li>
          <li
            class="submenu"
            :class="{ active: navOption == 'Pedidos' }"
            v-if="hidemenu2"
            @click="setOption('Pedidos'), setHistoryOptionPym('Default')"
          >
            &#10625; PEDIDOS
          </li>
          <li
            class="submenu"
            :class="{ active: navOption == 'globalparams' }"
            v-if="hidemenu2"
            @click="setOption('globalparams'), setHistoryOptionGlp('Default')"
          >
            &#10625; PRECIO ENVÍO 
          </li>
          <li
            class="submenu"
            :class="{ active: navOption == 'clientes' }"
            v-if="hidemenu2"
            @click="setOption('clientes'), setHistoryOptionUsr('Default')"
          >
            &#10625; CLIENTES
          </li>

          <li
            :class="{ active: navOption == 'suscripciones' }"
            @click="
              activeMenu(0),
                setOption('suscripciones'),
                setHistoryOptionSbs('Default')
            "
          >
            <div class="text-menu"><span>SUSCRIPCIONES</span></div>
            <div v-if="navOption == 'suscripciones'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>


          <li
            :class="{ active: navOption == 'MenuCo' }"
            @click="activeMenu(4), setOption('MenuCo')"
          >
            <div class="text-menu">MENÚ <span>COMENTARIOS</span></div>
            <div
              v-if="navOption == 'MenuCo' || hidemenu4"
              class="icon-active-menu"
            >
              <div class="ico-2"></div>
            </div>
          </li>
          <li
            class="submenu"
            :class="{ active: navOption == 'BloCo' }"
            v-if="hidemenu4"
            @click="setOption('BloCo'), setHistoryOptionBloCo('Default')"
          >
            &#10625; COMENTARIOS BLOG
          </li>
          <li
            class="submenu"
            :class="{ active: navOption == 'ProVal' }"
            v-if="hidemenu4"
            @click="setOption('ProVal'), setHistoryOptionProVal('Default')"
          >
            &#10625; VALORACIONES PRODUCTOS
          </li>
        </ul>
      </div>
    </div>
    <div class="box-2">
      <div class="triangulo"></div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "SideMenu",
  data() {
    return {
      navOption: "Default",
      hidemenu: false,
      hidemenu2: false,
      hidemenu3: false,
      hidemenu4: false,
    };
  },
 async created() {
   await this.getIdentity();

     let nav = localStorage.getItem('nav')

       if(nav){
           this.setHistoryOptionMain(nav)
           this.navOption = nav

           if(nav == 'Brand' || nav =='Atributo'|| nav =='Producto'  ){
               this.hidemenu=true
           }
           if(nav == 'Cupon' || nav =='Pedidos' || nav =='clientes' || nav == 'globalparams'){
               this.hidemenu2=true
           }
           if(nav == 'BloCo' || nav =='ProVal' ){
               this.hidemenu4=true
           }

           
       }
  },
  computed: {
    identity() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
  methods: {
    ...mapActions("main", ["setHistoryOptionMain"]),

    ...mapActions("usuarios", ["setHistoryOptionUsr"]),
    ...mapActions("usuarios", ["getAllInfoUsr"]),

    ...mapActions("category", ["setHistoryOptionCat"]),
    ...mapActions('category', ['getAllInfoCat']),

      ...mapActions("suscripciones", ["setHistoryOptionSbs"]),
    ...mapActions('suscripciones', ['getAllInfoSbs']),

    ...mapActions("product", ["setHistoryOptionPro"]),
    ...mapActions('product', ['getAllInfoPro']),

    ...mapActions("shopcar", ["setHistoryOptionCar"]),
    ...mapActions("shopcar", ["getAllInfoCar"]),

    ...mapActions("brand", ["setHistoryOptionBnd"]),
     ...mapActions('brand', ['getAllInfoBnd']),

     
    ...mapActions("atributo", ["setHistoryOptionAtt"]),
     ...mapActions('atributo', ['getAllInfoAtt']),

    ...mapActions("banner", ["setHistoryOptionBnr"]),
     ...mapActions("banner", ["getAllInfoBnr"]),

     ...mapActions("payment", ["setHistoryOptionPym"]),
     ...mapActions("payment", ["getAllInfoPym"]),

    ...mapActions("blog", ["setHistoryOptionBlo"]),
    ...mapActions("blog", ["getAllInfoBlo"]),

    ...mapActions("tag", ["setHistoryOptionTg"]),
    ...mapActions("tag", ["getAllInfoTg"]),

    ...mapActions("global_params", ["setHistoryOptionGlp"]),
    ...mapActions("global_params", ["getAllInfoGlp"]),


     ...mapActions("colaboradores", ["setHistoryOptionClb"]),
    ...mapActions("colaboradores", ["getAllInfoClb"]),

    ...mapActions("blog_category", ["setHistoryOptionBct"]),
    ...mapActions("blog_category", ["getAllInfoBct"]),

    ...mapActions("product_val", ["setHistoryOptionProVal"]),
    ...mapActions("product_val", ["getAllInfoProVal"]),

    ...mapActions("blog_comment", ["setHistoryOptionBloCo"]),
    ...mapActions("blog_comment", ["getAllInfoBloCo"]),

    activeMenu(value) {
      if (value == 1) {
        this.hidemenu ? (this.hidemenu = false) : (this.hidemenu = true);
      }else if(value == 2) {
        this.hidemenu2 ? (this.hidemenu2 = false) : (this.hidemenu2 = true);
      } else if (value == 3) {
        this.hidemenu3 ? (this.hidemenu3 = false) : (this.hidemenu3 = true);
      } else if (value == 4) {
        this.hidemenu4 ? (this.hidemenu4 = false) : (this.hidemenu4 = true);
      }else {
        this.hidemenu = false;
        this.hidemenu2 = false;
        this.hidemenu3 = false;
        this.hidemenu4 = false;
      }
    },
    getIdentity: async function () {
      let response = await this.$store.dispatch("admin/getData");
      return response;
    },

    setOption: function (option) {
      if (option == "MenuP" || option == "MenuC" || option == "MenuCo" || option == "MenuB") {
        this.navOption = option;
      } else if (option == "Mi Página") {
        window.open("https://agriga.com.mx/", "_blank");
      } else {
        this.navOption = option;
        localStorage.setItem("nav", option);
        this.setHistoryOptionMain(option);

        if(option =='Usuarios' || option =='clientes'){
              this.getAllInfoUsr("usuarios");
        }else if(option == 'Categorias'){
              this.getAllInfoCat('category')
        }else if(option == 'Banner'){
              this.getAllInfoBnr("banner");
        }else if(option == 'Brand'){
             this.getAllInfoBnd('brand')
        }else if(option == 'Atributo'){
             this.getAllInfoAtt('atributo')
        }else if(option == 'Producto'){
            this.getAllInfoPro('product')
        }else if(option == 'Cupon'){
             this.getAllInfoCar("cupones");
        }else if(option == 'suscripciones'){
             this.getAllInfoSbs("suscripciones");
        } else if (option == "BLOG") {
          this.getAllInfoBlo("blog");
        } else if (option == "BLOGCAT") {
          this.getAllInfoBct("blog_category");
        } else if (option == "TAG") {
          this.getAllInfoTg("tag");
        } else if (option == "colaboradores") {
          this.getAllInfoClb("colaboradores");
        } else if (option == "BloCo") {
          this.getAllInfoBloCo("blog_comments");
        } else if (option == "ProVal") {
          this.getAllInfoProVal("product_val");
        } else if (option == "Pedidos") {
         this.getAllInfoPym("payment");
        }else if (option == "globalparams") {
         this.getAllInfoGlp("global_params");
        }
      }
    },
  },
  filters: {
    splitUp: (value) => {
      if (!value) return "";
      value = value.toString();

      let splitStr = value.toUpperCase().split(" ");

      return splitStr[0].charAt(0) + splitStr[0].substring(1);
    },
  },
};
</script>
<style scoped>
#SideMenu {
  min-height: 59.25vw;
}

.box-1 {
  width: 16.614583333333332vw;
  background: var(--color-2);
}
.logo {
  margin-top: 4.166666666666666vw;
  margin-left: 2.03125vw;
}
.logo img {
  width: 12.604166666666666vw;
  height: 4.583333333333333vw;
}
.msg-user {
  margin-left: 2.03125vw;
  margin-top: 0.78125vw;
  width: 12.552083333333334vw;
  height: 2.03125vw;
  background: var(--color-5);
  border-radius: 1vw;
}
.msg-user p {
  color: var(--color-1);
  text-align: center;
  height: 2.03125vw;
  padding-top: 0.5vw;
  font-weight: 500;
  margin: 0px;
  font-size: 0.625vw;
}
.msg-user p span {
  font-weight: 800;
}

.box-2 {
  width: 4.21875vw;
}
.triangulo {
  width: 0;
  height: 0;
  border-right: 4.21875vw solid transparent;
  border-top: 4.21875vw solid var(--color-2);
}

.menu {
  margin-top: 5.677083333333333vw;
}

li.submenu {
  border: none !important;
}
.menu ul {
  margin-left: 2.03125vw;
  padding: 0vw;
}
.menu ul li {
  height: 1.6vw;
  width: 11.352083vw;
  border: 1px solid var(--color-5);
  color: var(--color-5);
  border-radius: 1.2vw;
  padding-left: 1vw;
  padding-top: 0.5vw;
  padding-bottom: 0.2vw;
  margin-bottom: 0.6020833333333334vw;
  cursor: pointer;
  display: flex;
  font-size: 0.7291666666666666vw;
}
.menu ul li span {
  font-weight: 700;
}

.text-menu {
  width: 9.5vw;
  padding-top: 0.1vw;
}

.active {
  background: var(--color-6);
}

li.active {
  border: 0px !important;
}
.icon-active,
.icon-active-menu {
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
  background: var(--color-5);
  border-radius: 1vw;
}
.ico {
  width: 0.6515624999999999vw;
  height: 0.6515624999999999vw;
  background: var(--color-2);
  border-radius: 1vw;
  margin-top: 0.33vw;
  margin-left: 0.34vw;
}
.ico-2 {
  margin-top: 0.55vw;
  margin-left: 0.3vw;
  width: 0;
  height: 0;
  border-right: 0.364583vw solid transparent;
  border-top: 0.364583vw solid var(--color-2);
  border-left: 0.364583vw solid transparent;
  border-bottom: 0.364583vw solid transparent;
}
</style>
