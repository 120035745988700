import { render, staticRenderFns } from "./addGlp.vue?vue&type=template&id=afb1b810&scoped=true&"
import script from "./addGlp.vue?vue&type=script&lang=js&"
export * from "./addGlp.vue?vue&type=script&lang=js&"
import style0 from "./addGlp.vue?vue&type=style&index=0&id=afb1b810&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afb1b810",
  null
  
)

export default component.exports