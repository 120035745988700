import { render, staticRenderFns } from "./addPro.vue?vue&type=template&id=946f80ce&scoped=true&"
import script from "./addPro.vue?vue&type=script&lang=js&"
export * from "./addPro.vue?vue&type=script&lang=js&"
import style0 from "./addPro.vue?vue&type=style&index=0&id=946f80ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "946f80ce",
  null
  
)

export default component.exports