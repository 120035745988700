<template>
    <div id="Sections">
         <HeaderSec /> 
         <div v-if="historyOption == 'Usuarios'">
             <Usuarios/>
         </div>
         <div v-if="historyOption == 'BLOG'">
            <Blog />
         </div>
         <div v-if="historyOption == 'Categorias'">
            <Categorias/>
         </div>
         <div v-if="historyOption == 'TAG'">
            <Tag />
         </div>
         <div v-if="historyOption == 'colaboradores'">
            <Colab />
         </div>
         <div v-if="historyOption == 'Default'">
            <Usuarios/>
         </div>
         <div v-if="historyOption == 'Producto'">
            <Productos/>
         </div>
         <div v-if="historyOption == 'Brand'">
            <Brand/>
         </div>
         <div v-if="historyOption == 'Banner'">
            <Banner/>
         </div>
         <div v-if="historyOption == 'Cupon'">
            <Cupon/>
         </div>
          <div v-if="historyOption == 'Pedidos'">
            <Pedidos/>
         </div> 
          <div v-if="historyOption == 'Atributo'">
            <Atributo/>
         </div> 
         <div v-if="historyOption == 'suscripciones'">
            <Suscripciones/>
         </div> 
         <div v-if="historyOption == 'BloCo'">
            <Comentarios/>
         </div> 
         <div v-if="historyOption == 'ProVal'">
            <Valoraciones/>
         </div> 
 <div v-if="historyOption == 'clientes'">
             <Clientes/>
         </div>
          <div v-if="historyOption == 'globalparams'">
             <GlobalParams/>
         </div>


        
    </div>
</template>
<script>

import {mapActions} from 'vuex';

import HeaderSec from '../components/HeaderSec.vue';
import Clientes from '../views/usuarios/clientes.vue';
import Usuarios from '../views/usuarios/usuarios.vue';
import Categorias from '../views/categorias/categorias.vue';
import Productos from '../views/products/productos.vue';
import Brand from '../views/products/brand.vue';
import Atributo from '../views/products/atributo.vue';
import Banner from '../views/banner/banner.vue';
import Cupon from '../views/shopcar/cupon.vue';
import Pedidos from '../views/pedidos/pedidos.vue';
import Suscripciones from '../views/suscripciones/suscripciones.vue';
import Blog from "../views/blog/blog.vue";
import Comentarios from "../views/comentarios/comentarios.vue";
import Valoraciones from "../views/comentarios/valoraciones.vue";
import Tag from "../views/tag/tag.vue";
import GlobalParams from "../views/global_params/globalparams.vue";
import Colab from "../views/colaboradores/colaboradores.vue";
export default {
  name: 'Sections',
components:{
      HeaderSec,
      Usuarios,
      Categorias,
      Productos,
      Brand,
      Banner,
      Cupon,
      Pedidos,
      Suscripciones,
      Comentarios,
      Valoraciones,
      Blog,
      Tag,
      Colab,
      Atributo,
      Clientes,
      GlobalParams
  },
 
   data(){
       return{
           search_value:'',
           status:'',
           message:'',
           option:'Default',
       }
   },
    computed:{
        historyOption(){
            return this.$store.getters["main/getHistoryOption"]
        },
    },
   created(){
        let option = localStorage.getItem('nav')
        if(option != undefined && option != null && option != ''){
            this.option = option
            this.setHistoryOptionMain(option)
        }
      
   },
   methods:{
        ...mapActions('main', ['setHistoryOptionMain']),
        ...mapActions('main', ['getHistoryOption']),
   }

   
    
 
}
</script>
<style scoped>
   
   #Sections{
    background: var(--color-5);
     min-height: 59.25VW;
     width:  73.177083VW;
     margin-left: 4.166666666666666VW;
   }

   .proximamente p{
       color: var(--color-1);
       font-weight: 700;
       font-size: 5vw;
       margin-top: 11.5vw;
       margin-left: 15vw;
   }


  



</style>
