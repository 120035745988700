import { render, staticRenderFns } from "./editBnr.vue?vue&type=template&id=668b1bfd&scoped=true&"
import script from "./editBnr.vue?vue&type=script&lang=js&"
export * from "./editBnr.vue?vue&type=script&lang=js&"
import style0 from "./editBnr.vue?vue&type=style&index=0&id=668b1bfd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "668b1bfd",
  null
  
)

export default component.exports